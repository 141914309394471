@import '../../../../components/assets/scss/common';

.error-table-container {
    background-color: white;
    border-radius: 9px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-bottom: 24px;
    &>.table-wrapper >.ui-table {
        width: 100%;
        min-width: 900px;
    }

    &>.table-wrapper >.ui-table>thead>tr>th:nth-child(1),
    &>.table-wrapper >.ui-table>tbody>tr>td:nth-child(1) {
        width: 100px;
    }

    &>.table-wrapper >.ui-table>thead>tr>th:nth-child(2),
    &>.table-wrapper >.ui-table>tbody>tr>td:nth-child(2) {
        width: 200px;
    }

    &>.table-wrapper >.ui-table>thead>tr>th:nth-child(3),
    &>.table-wrapper >.ui-table>tbody>tr>td:nth-child(3) {
        text-align: start;
        width: 500px;
        >span {
            word-break: break-all;
        }
    }

    //last child
    &>.table-wrapper >.ui-table>thead>tr>th:last-child,
    &>.table-wrapper >.ui-table>tbody>tr>td:last-child {
        width: 550px;
    }

    // Container for the device type bar chart
    .browser-type-container {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
}

// Error type styling
.error-type {
    display: flex;
    align-items: center;
    
    .error-icon {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-right: 8px;
        font-size: 16px;
    }
    
    .error-label {
        font-weight: 500;
    }
    
    // Color-coded error types
    &.error-rage {
        color: $color-dark-orange; // Orange for rage click
    }
    
    &.error-notfound {
        color: $color-marengo; // Grey for page not found
    }
    
    &.error-default {
        color: $color-coral-red; // Red for error-likes (JS errors, API errors)
    }
}
