@import './styles/tooltips.scss';
@import "./styles/z-indexes.scss";
@import "./components/assets/scss/common";

@-moz-keyframes spin {
    from { -moz-transform: rotate(0deg); }
    to { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
    from { -webkit-transform: rotate(0deg); }
    to { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
}

.spin {
    animation: spin 1s infinite linear;
}

body {
    &.locked {
        height: 100vh;
        overflow: hidden;
    }
}

.btn.btn-plain {
    box-shadow: none !important;
}

.timestamp {
    display: inline-block;
    background-color: #eee;
    padding: 5px 10px;
    border-radius: 15px;
}

.click-filter-form {
    display: flex;
    align-items: center;
    gap: 9px;
    &>select.form-control {
        display: inline-block;
        width: auto;
        margin: 0 5px;
    }
}

.code-block {
    display: block;
    padding: 15px;
    background-color: rgba(232, 62, 140, .05);
    border: 1px solid rgba(232, 62, 140, .25);
    border-radius: 5px;
}

// New Design Custom CSS

// Button style
.btn-grey {
    height: 38px;
    padding: 9px 18px;
    flex-shrink: 0;
    border-radius: 6px;
    border: 1px solid $color-anti-flash;
    background: $color-white-smoke;
    color: $color-charcoal;
    font-size: 13px;
    font-weight: 500;
    &:hover {
        background: $color-baby-powder;
        color: $color-charcoal; 
    }
    &:focus {
        outline: none;
    }
    .icon svg{
        width: 20px;
        height: 20px;
        margin: -3px 9px 0 0;
    }
    .chevron-icon {
        color: $color-cadet-grey;
        margin: -2px 0 0 24px;
    }
}

// Card shadow and border: overwrite bootstrap shadow
.shadow {
    border: none;
    border-radius: 6px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.10) !important;
    // font in dashboard card use this color, need design to decide if apply for all cards
    // color: $color-charcoal;
}

.cursor-pointer{
    cursor: pointer;
}

.horizontal-divider {
    border-top: 1px solid $color-alice-blue;
    margin: 10px 0;
}